<template>
  <div id="accommodation">
    <section
      class="page-header"
      style="background-image: url('/imgs/accommodation-01.png')"
    >
      <div class="caption">
        <h4>{{ $lang("title") }}</h4>
        <i class="isologo"></i>
        <h1>{{ $lang("subtitle") }}</h1>
      </div>
    </section>

    <section class="bg-tertiary py-6 text-center text-white decoration-3">
      <div class="container">
        <p>{{ $lang("desc") }}</p>
      </div>
    </section>

    <section class="section-14 py-6 text-center">
      <div class="container">
        <h4 class="mb-5 text-primary">{{ $lang("features.title") }}</h4>

        <div
          class="card card-medium-tint no-shadow text-dark d-flex align-items-stretch mb-4"
          v-for="a of $lang('features.items')"
          :key="a.title"
        >
          <div
            class="img-container flex-1"
            :style="`background-image: url('${a.img}')`"
          ></div>
          <div class="card-body flex-2 p-5 text-left">
            <h4 class="mb-4" :class="`text-${a.color}`">{{ a.title }}</h4>
            <p class="mb-4 opacity-8">
              {{ a.details }}
            </p>
          </div>
        </div>

        <button
          class="btn btn-primary px-5 mt-5 mx-auto"
          @click="$router.push('/contact-us')"
        >
          {{ $lang("features.button") }}
        </button>
      </div>
    </section>
  </div>
</template>
